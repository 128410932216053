<template>
	<div class="w100 bg-fff wtm_height2 pl-24 pr-24 pt-30 box autobox">
		<div class="w100 dp-f fw-w mb-24">
			<div class="input_box w-200">
				<el-input class="input search_input" placeholder="请输入搜索合同标题" v-model="state.searchKey.contractName"
					:maxlength="config.inputMaxlength" show-word-limit>
					<template #append>
						<img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="getDataList(currentPage.value)">
					</template>
				</el-input>
			</div>
			<div class="input_box w-450">
				<div class="input_title">签约截止日期：</div>
				<el-date-picker class="input" v-model="state.searchKey.contractDeadline" type="datetimerange" range-separator="→"
					start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss" />
			</div>
			<oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
				@buttonclick="getDataList(currentPage.value)">
			</oabutton>
		</div>
		<el-table :data="state.Notice" class="mb-20"
			:header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
			row-key="id" @selection-change="selectionChange" v-loading="loading" :element-loading-text="config.loading.text"
			:element-loading-spinner="config.loading.spinner">
			<!-- <el-table-column :align="'center'" type="selection" width="55" /> -->
			<!-- <el-table-column :align="'center'" prop="contractName" label="存证ID" /> -->
			<el-table-column :align="'center'" prop="contractName" label="合同标题" />
			<!-- <el-table-column :align="'center'" prop="contractTypeStr" label="合同类型" /> -->
			<el-table-column :align="'center'" prop="initiatorStr" label="发起方" />
			<!-- <el-table-column :align="'center'" prop="sponsorStr" label="发起人" /> -->
			<el-table-column :align="'center'" prop="showSignatory" label="签署方" width="180" />
			<el-table-column :align="'center'" label="合同状态">
				<template #default="scope">
					<div class="dp-fc contractStatus" :class="'contractStatus' + scope.row.contractStatus">
						<div class="line"></div>
						<div class="w-6 h-6 icon mr-9"></div>
						<div class="w-70 ta-l">{{ getcontractStatus(scope.row.contractStatus) }}</div>
					</div>
				</template>
			</el-table-column>
			<!-- <el-table-column :align="'center'" prop="initiateData" label="发起日期" /> -->
			<el-table-column :align="'center'" prop="contractDeadline" label="签署日期" width="120" />
			<el-table-column :align="'center'" label="公证书" width="120">
				<template #default="scope">
					<div v-if="scope.row.gzsStatus==0"> 
						<el-tag class="mx-1" size="large">已付款</el-tag>
						<!-- <el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link> -->
					</div>
					<div v-else-if="scope.row.gzsStatus==1">
						<el-tag class="mx-1" size="large">出证中</el-tag>
					</div>
					<div v-else-if="scope.row.gzsStatus==2">
						<el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link>
					</div>
					<div v-else-if="scope.row.gzsStatus==3">
						<el-tag class="mx-1" size="large">待审核</el-tag>
					</div>
					<div v-else>
						<el-tag class="mx-1" size="large">未申请</el-tag>
					</div>
				</template>
			</el-table-column>
			<el-table-column :align="'center'" prop="contractDeadline" label="保全函" width="120">
				<template #default="scope">
					<div v-if="scope.row.bqhStatus==0"> 
						<el-tag class="mx-1" size="large">已付款</el-tag>
						<!-- <el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link> -->
					</div>
					<div v-else-if="scope.row.bqhStatus==1">
						<el-tag class="mx-1" size="large">出证中</el-tag>
					</div>
					<div v-else-if="scope.row.bqhStatus==2">
						<el-link :href="scope.row.bqhPath" type="success">{{ scope.row.bqhCreateDate }}</el-link>
					</div>
					<div v-else-if="scope.row.bqhStatus==3">
						<el-tag class="mx-1" size="large">待审核</el-tag>
					</div>
					<div v-else>
						<el-tag class="mx-1" size="large">未申请</el-tag>
					</div>
				</template>
			</el-table-column>
			<el-table-column :align="'center'" label="操作" width="180">
				<template #default="scope">
					<div class="dp-fc butbox fw-w">
						<!-- <div class="Editor_span" v-if="scope.row.contractStatus == 0"
							@click="clickUserCheck(() => { signClick(scope.row.id, 1) })">签署</div> -->
						<div class="Editor_span" @click="showPay(scope.row)">公证申请</div>
						<div class="Editor_span" @click="showDetail(scope.row)">详情</div>
						<!-- <div class="Editor_span" v-if="scope.row.contractStatus == 4" @click="InitiateContract(scope.row, 1)">编辑</div> -->
						<!-- <div class="Editor_span" v-if="scope.row.contractStatus == 2" @click="batchDownloadFile([scope.row.id])">下载</div> -->
						<!-- router_push_name('sign_details', { id: scope.row.id }, undefined, true) -->
						<!-- <div class="Delete_span" v-permission="'contractDocuments:withdraw'">撤回</div> -->
						<!-- <div class="Delete_span" v-if="scope.row.contractStatus == 3 || scope.row.contractStatus == 4"
							v-permission="'contractDocuments:del'">删除
						</div> -->
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
			layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage">
		</el-pagination>
	</div>
	<!-- <sign ref="signRef"></sign> -->
	<evidpay ref="payRef" @withdrawEmit="getDataList()" />
	<eviddetails ref="detailsRef" @withdrawEmit="getDataList()" />
	<!-- <willSign ref="willSignRef" @Emit="getDataList()" /> -->
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import { objdata_ret } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { export2Excel } from "@/utils/server/file";
import { getcontractStatus } from "@/utils/server/getcode.js"
// import sign from "@/views/contractDocuments/components/sign.vue"//批量签署
// import Detailsdrawer from "@/views/contractDocuments/components/details.vue"//详情
// import willSign from '@/views/operation/signContract/components/willingness/dialog.vue'//签署意愿
import evidpay from '../../contractDocuments/components/evidpay.vue';
import eviddetails from '../../contractDocuments/components/eviddetails.vue';
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import { router_push_name } from '@/utils/server/router'
import { batchDownloadFile } from '@/utils/server/upload'
import { getUserOrCompany, clickUserCheck } from '@/utils/base/realname.js'

import qs from "qs";
const props = defineProps({
	// 当前选择的下标
	butIndex: {
		type: Number,
	},
});
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const signRef = ref();//批量签署弹框ref
const detailsRef = ref();//详情弹框ref
const payRef = ref();
const willSignRef = ref()//签署意愿
const state = reactive({
	multipleSelection: [],//选中的数据
	delOperation: true,//批量删除按钮是否禁用
	batchOperation: true,//批量签署按钮是否禁用
	downloadOperation: true,//批量下载按钮是否禁用
	searchKey: {
		contractName: '',//合同名称
		contractDeadline: null,//签约截止日期
	},//搜索绑定值
	Notice: [],//表格数据
	columns: [
		{ title: "合同标题", key: "contractName" },
		{ title: "合同类型", key: "contractTypeStr" },
		{ title: "发起方", key: "initiatorStr" },
		{ title: "发起人", key: "sponsorStr" },
		{ title: "签署方", key: "showSignatory" },
		{ title: "合同状态", key: "contractStatus", reset: { 0: '待我处理', 1: '签署中', 2: '已完成', 3: '已过期', 4: '草稿箱', 5: '抄送' } },
		{ title: "签约截止日期", key: "contractDeadline" },
	],
})
const BatchSigning=()=>{
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/isOKSealPosition',
		data: qs.stringify({
			contract_id: state.multipleSelection.map(item => {
				return item.id
			}).join(','),
		}),
	}).then((res) => {
		if(res.data){
			unref(willSignRef).show(state.multipleSelection)
		}
	})
}
const signClick = ((id, type = 0) => {
	console.log('id, type',id, type)
	router_push_name('sign_details', { id: id, type: type }, undefined, true)
})
const InitiateContract = (el = {}, type) => {
	if (getUserOrCompany() == 1) {
		if (type == 1) {
			router_push_name('operation_initiate_contract', { data: JSON.stringify(el) })
		} else {
			router_push_name('operation_initiate_contract')
		}
	}
}
// 下载下拉菜单点击
const downloadCommand = (key) => {
	if (key == 'download') {
		batchDownloadFile(state.multipleSelection.map(item => { return item.id }))
	}
}
// 重置搜索数据
const resetsearchKey = () => {
	objdata_ret(state.searchKey)
	state.searchKey.contractDeadline = null
}
const getDataList = (val = 1) => {
	let url = '/initiate-contract/contract/list'
	state.multipleSelection = []
	let json = [];
	// let status=''
	// if (props.butIndex == 1) {
	// 	url = '/initiate-contract/contract/doTask'
	// 	status=null
	// } else if (props.butIndex == 2) {
	// 	url = '/initiate-contract/contract/toTask'
	// 	status=null
	// } else if (props.butIndex == 3) {
	// 	status='2'
	// } else if (props.butIndex == 4) {
	// 	status='3'
	// } else if (props.butIndex == 5) {
	// 	status='4'
	// } else if (props.butIndex == 6) {
	// 	url = '/initiate-contract/contract/sendMe'
	// 	status=null
	// }
	currentPage.value = val;
	state.Notice = []
	loading.value = true
	let cdata={
		page: val,
		name: state.searchKey.contractName,
		dateTime: JSON.stringify(state.searchKey.contractDeadline),
		status: "2",
		evid: true
	}
	if(json.length>0){
		cdata.paramData=JSON.stringify(json)
	}
	// if(status||status===''){
	// 	cdata.status=status
	// }
	queryResourceTable(url, qs.stringify(cdata)).then((res) => {
		// 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
		if (val !== res.data.current) {
			getDataList(res.data.current)
			return
		}
		totalPage.value = res.data.total;
		res.data.records.forEach(item => {
			// item.bqhStatus = "未申请"
			// if (item.bqhPath) {
			// 	item.bqhStatus = item.bqhCreateDate
			// }
			// item.gzsStatus = "未申请"
			// if (item.gzsPath) {
			// 	item.gzsStatus = item.gzsCreateDate
			// }
			item.showSignatory = ''
			item.contractSignRecords.forEach((item2, ind2) => {
				item.contractTypeStr = item.contractTypeStr ? item.contractTypeStr : '/'
				item.initiatorStr = item.initiatorStr ? item.initiatorStr : '/'
				if (item2.contractSignPersonList.length > 0) {
					item.showSignatory += ind2 == 0 ? '' : ','
					item.showSignatory += item2.signerType == 1 ? item2.contractSignPersonList[0].companyName : item2.contractSignPersonList[0].signerName
				}
			})
		});
		state.Notice = res.data.records
		loading.value = false;
	}).catch((err) => {
		console.warn("getDataList err: ", err)
		if (err.message != 'stop')
			loading.value = false;
	})
}
getDataList()
// 分页改变方法
const handleCurrentChange = ((val) => {
	getDataList(val)
})
// 展示详情数据
const showDetail = (row) => {
	unref(detailsRef).getDetails(row)
}
// 展示详情数据
const showPay = (row) => {
	unref(payRef).getDetails(row)
}
// 批量签署点击
const sings = () => {
	unref(signRef).getData(state.Notice)
}
// 表格选中事件
const selectionChange = (val) => {
	// console.log('选中改变',val)
	state.multipleSelection = val
	if (val.length == 0) {
		state.batchOperation=true
		state.delOperation = true
		state.downloadOperation = true
	} else {
		let delData = false
		let downloadData = false
		let batchData = false
		for (let i = 0; i < val.length; i++) {
		// { title: "合同状态", key: "contractStatus", reset: { 0: '待我处理', 1: '签署中', 2: '已完成', 3: '已过期', 4: '草稿箱', 5: '抄送' } },
			if (val[i].contractStatus !== 0 ) {
				batchData = true
			}
			if (val[i].contractStatus != 3 && val[i].contractStatus != 4) {
				delData = true
			}
			if (val[i].contractStatus != 2) {
				downloadData = true
			}
		}
		state.batchOperation = batchData
		state.delOperation = delData
		state.downloadOperation = downloadData
	}
}

defineExpose({
	resetsearchKey,
	getDataList
});
</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";

.el-link {
  margin-right: 8px;
  font-size: small;
}
.box {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
}

.butbox {
	div+div {
		border-left: 1px solid rgba(216, 216, 216, 1);
		padding-left: 10px;
		margin-left: 10px;
	}

	:last-child {
		border-right: none;
	}
}

::v-deep .el-table__row {
	position: relative;
}

::v-deep .el-table .el-table__cell {
	position: static;
}

::v-deep .contractStatus {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;

	.icon {
		background: #999999;
		border-radius: 50%;
	}

	.line {
		position: absolute;
		left: 0px;
		width: 4px;
		height: 50px;
		background: $active-theme;
		border-radius: 100px;
		display: none;
	}
}

::v-deep .contractStatus0 {
	color: #1890FF;

	.icon {
		background: #1890FF;
	}

	.line {
		display: block;
	}
}

::v-deep .contractStatus1 {
	color: #52C41A;

	.icon {
		background: #52C41A;
	}
}

::v-deep .contractStatus2 {
	color: #FAAD14;

	.icon {
		background: #FAAD14;
	}
}
</style>