<template>
	<el-drawer custom-class="drawerIconNone" v-model="drawerVisible" :close-on-click-modal="false" title="合同详情"
		direction="rtl" size="500px">
		<div class="detailsbox">
			<div class="ovbox">
				<!-- {{ state.baseInfo.contractStatus }} -->
				<div class="labelbox dp-f">
					<div class="label">合同类型:</div>{{ state.baseInfo.contractTypeStr }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">合同标题:</div>{{ state.baseInfo.contractName }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">状态:</div>
					<div :class="['status', 'status' + state.baseInfo.contractStatus]">{{
						getcontractStatus(state.baseInfo.contractStatus) }}</div>
				</div>
				<div class="labelbox dp-f">
					<div class="label">签署截止日期:</div>{{ state.baseInfo.contractDeadline }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">合同发送日期:</div>{{ state.baseInfo.initiateData }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">发起方:</div>
					<template v-if="state.baseInfo.initiatorStr&&state.baseInfo.initiatorStr!='/'">
						{{ state.baseInfo.initiatorStr }}&
					</template>
					{{ state.baseInfo.sponsorStr }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">保全函:</div>
					<div v-if="state.baseInfo.bqhStatus==0"> 
						<el-tag class="mx-1" size="large">已付款</el-tag>
						<!-- <el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link> -->
					</div>
					<div v-else-if="state.baseInfo.bqhStatus==1">
						<el-tag class="mx-1" size="large">出证中</el-tag>
					</div>
					<div v-else-if="state.baseInfo.bqhStatus==2">
						<el-link :href="state.baseInfo.bqhPath" type="success">下载</el-link>
					</div>
					<div v-else-if="state.baseInfo.bqhStatus==3">
						<el-tag class="mx-1" size="large">待审核</el-tag>
					</div>
					<div v-else>
						<el-tag class="mx-1" size="large">未申请</el-tag>
					</div>
				</div>
				<div class="labelbox dp-f">
					<div class="label">公证书:</div>
					<div v-if="state.baseInfo.gzsStatus==0"> 
						<el-tag class="mx-1" size="large">已付款</el-tag>
						<!-- <el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link> -->
					</div>
					<div v-else-if="state.baseInfo.gzsStatus==1">
						<el-tag class="mx-1" size="large">出证中</el-tag>
					</div>
					<div v-else-if="state.baseInfo.gzsStatus==2">
						<el-link :href="state.baseInfo.gzsPath" type="success">下载</el-link>
					</div>
					<div v-else-if="state.baseInfo.gzsStatus==3">
						<el-tag class="mx-1" size="large">待审核</el-tag>
					</div>
					<div v-else>
						<el-tag class="mx-1" size="large">未申请</el-tag>
					</div>
				</div>
				<el-divider />
				<dev>
					<el-text class="mx-1" size="large">系统存证记录({{ state.baseInfo.transId }})</el-text>
				</dev>
				<el-divider />
				<dev>
					<el-text class="mx-1" size="large">申请记录</el-text>
					<el-table :data="state.applyList" border stripe style="width: 100%">
						<el-table-column type="index" width="50"/>
						<el-table-column label="申请类型" width="90">
							<template #default="scope">
								<div v-if="scope.row.type == 1">保全函</div>
								<div v-else>公证书</div>
							</template>
						</el-table-column>
						<el-table-column label="状态"  width="90">
							<template #default="scope">
								<div v-if="scope.row.status==0"> 
									<el-tag class="mx-1" size="large">已付款</el-tag>
									<!-- <el-link :href="scope.row.gzsPath" type="success">{{ scope.row.gzsCreateDate }}</el-link> -->
								</div>
								<div v-else-if="scope.row.status==1">
									<el-tag class="mx-1" size="large">出证中</el-tag>
								</div>
								<div v-else-if="scope.row.status==2">
									<el-link :href="scope.row.bqhPath" type="success">下载</el-link>
								</div>
								<div v-else-if="scope.row.status==3">
									<el-tag class="mx-1" size="large">待审核</el-tag>
								</div>
								<div v-else>
									<el-tag class="mx-1" size="large">未申请</el-tag>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="createDate" label="申请时间" />
					</el-table>
				</dev>
				<!-- <div class="dp-f jc-sb ai-fe mt-50 mb-18">
					<div class="fs-18">合同签署</div>
					<div class="fs-14 c-999">{{ getSignOrder(state.baseInfo.signOrder) }}</div>
				</div> -->
				<!-- <items :items="state.baseInfo.contractSignRecords"></items> -->
			</div>
			<!-- return code == 0?'待我处理':code == 1?'签署中':code == 2?'已完成':code == 3?'已过期':code == 4?'草稿箱':code == 5?'抄送':'未知' -->
			<div class="fotbox dp-f jc-fe ai-c">
				<oabutton class="searcML" width='100' height='40' title="取消" CSStype=3 @buttonclick="drawerVisible = false" />
				<oabutton class="searcML mr-12" width='100' height='40' title="查看合同" CSStype=2 @buttonclick="signClick()" />
			</div>
		</div>
	</el-drawer>
</template>
<script setup>
import { reactive, ref, defineEmits, computed } from 'vue'
import { getcontractStatus, getSignOrder } from "@/utils/server/getcode.js"
import { router_push_name } from "@/utils/server/router.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import {clickUserCheck} from "@/utils/base/realname.js"
import { httpToken } from "@/utils/request";
import qs from "qs";
import items from "./items.vue"
const dialogloading = ref(false);// 弹框按钮loading 显示
const emit = defineEmits(['withdrawEmit'])
const drawerVisible = ref(false)//抽屉显示
const state = reactive({
	selectionCount: 0,//选中数量
	baseInfo: {},
	withdrawIs: false,//是否可撤回
	applyList: []
})
const userInfo = computed(() => {
	return JSON.parse(window.sessionStorage.user).user_info
})

const getEvidList = () => {
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/evidlist',
		data: qs.stringify({
			transId: state.baseInfo.transId 
		}),
	}).then((res) => {
		console.log(res)
		if (res.data.length > 0) {
			state.applyList = res.data
		}
	})
}

const getDetails = ((data) => {
	state.baseInfo = data
	drawerVisible.value = true
	getEvidList()
})
// isAuthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
// 0默认 1签署
const signClick = ((type=0) => {
	router_push_name('sign_details', { id: state.baseInfo.id,type:type }, undefined, true)
})
defineExpose({
	getDetails
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";

.detailsbox {
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	padding: 0 34px;
	padding-top: 34px;

	.ovbox {
		height: calc(100% - 60px);
		overflow: auto;
	}

	.labelbox {
		.status {
			color: #0083FF;
		}

		.status3,
		.status4 {
			color: #999999;
		}
	}

	.fotbox {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px -1px 8px 0px rgba(219, 219, 219, 0.5);
	}

	// 隐藏滚动条
	::-webkit-scrollbar {
		width: 0 !important
	}
}
</style>